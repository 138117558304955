import { Badge, useColorModeValue } from '@chakra-ui/react';

const Status = props => {
  const { row } = props;

  const bgStatus = useColorModeValue('gray.400', '#1a202c');
  const colorStatus = useColorModeValue('white', 'gray.400');

  return (
    <Badge
      bg={row.streaming ? 'green.400' : bgStatus}
      color={row.streaming ? 'white' : colorStatus}
      fontSize="16px"
      p="3px 10px"
      borderRadius="8px"
    >
      {row.streaming ? 'live' : 'offline'}
    </Badge>
  );
};

export default Status;
