import { Flex, SimpleGrid } from '@chakra-ui/react';
import CardStatistics from './components/CardStatistics';
import { FaMoneyBill, FaUser, FaUsers, FaWallet } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { ErrorFetchApi, LineChart, Loading } from '../../../components';
import ChartStatistics from './components/ChartStatistics';

const Dashboard = props => {
  const { agencyId, createdAt, postData } = props;

  const fetchParams = {
    agency_id: agencyId,
  };

  const { isLoading, error, data, isError, isRefetching, isRefetchError } =
    useQuery(
      'requestUser',
      () => postData('engine/agency_dashboard', fetchParams),
      {
        refetchOnWindowFocus: false,
      }
    );

  if (isLoading || isRefetching) return <Loading type="bar" />;

  if (isError || isRefetchError || !data)
    return <ErrorFetchApi title={error !== null ? error.message : ''} />;

  return (
    <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <CardStatistics
          title={"Today's Live"}
          amount={`${
            data.totalTalentLive ? data.totalTalentLive : 0
          } Talent Live`}
          // percentage={5}
          icon={<FaUser h="24px" w="24px" color="white" />}
        />
        <CardStatistics
          title="Total Talents"
          amount={`${data.totalTalent ? data.totalTalent : 0} Talents`}
          // percentage={-14}
          icon={<FaUsers h="24px" w="24px" color="white" />}
        />
        <CardStatistics
          title="Total Beelli Gold"
          amount={`BG ${data.totalBg ? data.totalBg.toLocaleString() : 0}`}
          // percentage={8}
          icon={<FaWallet h="24px" w="24px" color="white" />}
        />
        <CardStatistics
          title="Total Bonus"
          amount={
            data.totalBg
              ? ((((5 / 100) * data.totalBg) / 7) * 192).toLocaleString(
                  'id-ID',
                  {
                    style: 'currency',
                    currency: 'IDR',
                    currencyDisplay: 'code',
                  }
                )
              : 'IDR 0'
          }
          // percentage={5}
          icon={<FaMoneyBill h="24px" w="24px" color="white" />}
        />
      </SimpleGrid>

      <ChartStatistics
        chart={<LineChart agencyId={agencyId} createdAt={createdAt} />}
      />
    </Flex>
  );
};

export default Dashboard;
