import { Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import IconBox from '../Icon/IconBox';

const CreateLink = props => {
  const { data, activeRoute } = props;
  const activeBg = useColorModeValue('white', 'gray.700');
  const inactiveBg = useColorModeValue('white', 'gray.700');
  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue('white', 'gray.400');

  return data.map(menu => {
    return (
      <NavLink to={menu.path} key={menu.id}>
        {activeRoute(menu.path) === 'active' ? (
          <Button
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            bg={activeBg}
            mb={{
              xl: '12px',
            }}
            mx={{
              xl: 'auto',
            }}
            ps={{
              sm: '10px',
              xl: '16px',
            }}
            py="12px"
            borderRadius="15px"
            w="100%"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex>
              <IconBox bg="teal.300" color="white" h="30px" w="30px" me="12px">
                {menu.icon}
              </IconBox>
              <Text color={activeColor} my="auto" fontSize="sm">
                {menu.name}
              </Text>
            </Flex>
          </Button>
        ) : (
          <Button
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            bg="transparent"
            mb={{
              xl: '12px',
            }}
            mx={{
              xl: 'auto',
            }}
            py="12px"
            ps={{
              sm: '10px',
              xl: '16px',
            }}
            borderRadius="15px"
            w="100%"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex>
              <IconBox
                bg={inactiveBg}
                color="teal.300"
                h="30px"
                w="30px"
                me="12px"
              >
                {menu.icon}
              </IconBox>
              <Text color={inactiveColor} my="auto" fontSize="sm">
                {menu.name}
              </Text>
            </Flex>
          </Button>
        )}
      </NavLink>
    );
  });
};

export default CreateLink;
