import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'react-auth-kit';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import theme from './themes';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme} resetCss={false} w="100%">
        <AuthProvider
          authType="cookie"
          authName="_enter"
          cookieDomain={window.location.hostname}
          cookieSecure={window.location.protocol === 'https:'}
        >
          <RouterProvider router={routes} />
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
