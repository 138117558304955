import {
  Portal,
  useBoolean,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { useRef } from 'react';
import { useQuery } from 'react-query';
import {
  AdminNavbar,
  Alert,
  ErrorFetchApi,
  Loading,
  MainPanel,
  Sidebar,
} from '../../components';
import PanelContent from '../../components/Layout/PanelContent';
import { useLocation } from 'react-router-dom';
import PanelContainer from '../../components/Layout/PanelContainer';
import Dashboard from '../../views/home/Dashboard';
import Talents from '../../views/home/Talent';
import Request from '../../views/home/Request';

const AdminLayout = props => {
  const [loading, setIsLoading] = useBoolean();
  const { getCurrentUser, postData, signOut, sessionToken } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [alertData, setAlertData] = useControllableState({
    defaultValue: { title: '', message: '' },
  });

  const location = useLocation();

  const { isLoading, error, data, isError, status } = useQuery(
    'currentUser',
    getCurrentUser
    // { refetchOnWindowFocus: false }
  );

  const handleSignout = () => {
    setAlertData({
      ...alertData,
      title: 'Log out',
      message: 'Are you sure to logout?',
    });
    onOpen();
  };

  const logOut = async () => {
    setIsLoading.on();
    const token = { session_token: sessionToken };
    axios
      .post(process.env.REACT_APP_ENGINE_URL + 'engine/logout', token)
      .then(() => signOut())
      .catch(err => err.message)
      .finally(() => {
        setIsLoading.off();
      });
  };

  const onCloseAlert = () => {
    setAlertData({ title: '', message: '' });
    onClose();
  };

  const getViews = routes => {
    switch (routes) {
      case '/dashboard':
        return (
          <Dashboard
            agencyId={data.objectId}
            createdAt={data.createdAt}
            postData={postData}
          />
        );

      case '/talent':
        return <Talents agencyId={data.objectId} postData={postData} />;

      case '/request':
        return <Request agencyId={data.objectId} postData={postData} />;

      default:
        break;
    }
  };

  if (isLoading || loading || status === 'loading')
    return <Loading type="hash" />;

  if (isError || !data)
    return <ErrorFetchApi title={error.message} onClick={getCurrentUser} />;

  return (
    <>
      <Sidebar logoText={data.name} logoImg={data.avatar && data.avatar} />
      <MainPanel
        w={{
          base: '100%',
          xl: 'calc(100% - 275px)',
        }}
      >
        <Portal>
          <AdminNavbar
            handleSignout={handleSignout}
            logoText={data.name}
            logoImg={data.avatar && data.avatar}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>{getViews(location.pathname)}</PanelContainer>
        </PanelContent>
      </MainPanel>
      <Alert
        isOpen={isOpen}
        onClose={onCloseAlert}
        onClick={logOut}
        cancelRef={cancelRef}
        title={alertData.title}
        message={alertData.message}
      />
    </>
  );
};

export default AdminLayout;
