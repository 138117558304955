import { Avatar, Box, Center, Link, Stack, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Separator from '../Separator/Separator';
import { menuData } from '../../variables/LinkMenu';
import CreateLink from './CreateLink';

const SidebarContent = props => {
  const { logoText, logoImg } = props;

  const location = useLocation();

  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : '';
  };

  return (
    <>
      <Box pt={'25px'} mb="12px">
        <Link
          href={''}
          target="_blank"
          display="flex"
          lineHeight="100%"
          mb="30px"
          fontWeight="bold"
          justifyContent="center"
          alignItems="center"
          fontSize="11px"
        >
          <Center
            w={'35px'}
            h={'35px'}
            borderRadius={'full'}
            bg={'gray.200'}
            mr={'5px'}
          >
            <Avatar src={logoImg} size={'sm'} />
          </Center>
          <Text fontSize="md">{logoText}</Text>
        </Link>
        <Separator />
      </Box>
      <Stack direction="column" mb="40px">
        <Box>
          <CreateLink data={menuData} activeRoute={activeRoute} />
        </Box>
      </Stack>
    </>
  );
};

export default SidebarContent;
