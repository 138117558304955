import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import AdminNavbarLink from './AdminNavbarLink';
import { useLocation } from 'react-router-dom';

const CreateBreadCrumb = ({ title }) => {
  return (
    <Box mb={{ sm: '8px', md: '0px' }}>
      <Breadcrumb>
        <BreadcrumbItem color={useColorModeValue('gray.700', 'gray.200')}>
          <BreadcrumbLink
            href="#"
            color={useColorModeValue('gray.400', 'gray.200')}
          >
            Pages
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem color={useColorModeValue('gray.700', 'gray.200')}>
          <BreadcrumbLink
            href="#"
            color={useColorModeValue('gray.700', 'gray.200')}
          >
            {title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Link
        color={useColorModeValue('gray.700', 'gray.200')}
        href="#"
        bg="inherit"
        borderRadius="inherit"
        fontWeight="bold"
        _hover={{ color: useColorModeValue('gray.700', 'gray.200') }}
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent',
        }}
        _focus={{
          boxShadow: 'none',
        }}
      >
        {title}
      </Link>
    </Box>
  );
};

const AdminNavbar = props => {
  const { handleSignout, logoText, logoImg } = props;
  const location = useLocation();

  const breadCrumb = routes => {
    switch (routes) {
      case '/dashboard':
        return <CreateBreadCrumb title={'Dashboard'} />;

      case '/talent':
        return <CreateBreadCrumb title={'Talents'} />;

      case '/request':
        return <CreateBreadCrumb title={'Requests'} />;

      default:
        break;
    }
  };

  return (
    <Flex
      position={'absolute'}
      boxShadow={'none'}
      bg={'none'}
      borderColor={'transparent'}
      filter={'none'}
      backdropFilter={'blur(21px)'}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={'0px'}
      pb="8px"
      left={document.documentElement.dir === 'rtl' ? '30px' : ''}
      right={document.documentElement.dir === 'rtl' ? '' : '30px'}
      px={{
        sm: '15px',
        md: '30px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top="18px"
      w={{ sm: 'calc(100vw - 30px)', xl: 'calc(100vw - 75px - 275px)' }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
      >
        {breadCrumb(location.pathname)}
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLink
            handleSignout={handleSignout}
            logoText={logoText}
            logoImg={logoImg}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AdminNavbar;
