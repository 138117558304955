import axios from 'axios';
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { Navigate } from 'react-router-dom';
import AdminLayout from '../layouts/home';

const Home = () => {
  const isAuth = useIsAuthenticated();
  const auth = useAuthUser();
  const signOut = useSignOut();

  const getCurrentUser = async () =>
    axios
      .post(process.env.REACT_APP_ENGINE_URL + 'engine/current', {
        session_token: auth().sessionToken,
      })
      .then(res => (res.data.error ? signOut() : res.data))
      .catch(err => err.message);

  const postData = async (url, params) =>
    axios
      .post(process.env.REACT_APP_ENGINE_URL + url, params)
      .then(r => r.data)
      .catch(err => err.message);

  return isAuth() ? (
    <AdminLayout
      getCurrentUser={getCurrentUser}
      postData={postData}
      signOut={signOut}
      sessionToken={auth().sessionToken}
    />
  ) : (
    <Navigate to={'/sign-in'} replace />
  );
};

export default Home;
