import { Center } from '@chakra-ui/react';
import { BarLoader, HashLoader } from 'react-spinners';

const Loading = props => {
  const { type } = props;

  if (type === 'bar') {
    return (
      <Center minH={'100vh'}>
        <BarLoader color={'#36d7b7'} size={100} />
      </Center>
    );
  }

  return (
    <Center minH={'100vh'}>
      <HashLoader color={'#36d7b7'} size={100} />
    </Center>
  );
};

export default Loading;
