import { Box, Heading, Text, Button, Center } from '@chakra-ui/react';

const NotFound = ({ status, statusText, message, onClick }) => {
  return (
    <Center minH={'90vh'}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          {status}
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          {statusText}
        </Text>
        <Text color={'gray.500'} mb={6}>
          {message}
        </Text>

        <Button
          colorScheme="teal"
          bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
          color="white"
          variant="solid"
          onClick={onClick}
        >
          Go to Home
        </Button>
      </Box>
    </Center>
  );
};

export default NotFound;
