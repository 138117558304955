import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { Alert, Blur, Loading, ThemeSwitch } from '../../components';

const AuthLayout = props => {
  const { isLoading, isOpen, onCloseAlert, onClick, alertData, children } =
    props;
  const cancelRef = useRef();

  useEffect(() => {
    document.body.style.overflow = 'unset';

    return () => {};
  });

  return isLoading ? (
    <Loading type="hash" />
  ) : (
    <>
      <Blur
        position={'absolute'}
        top={-10}
        left={-10}
        style={{ filter: 'blur(70px)' }}
      />
      <Box textAlign={'end'}>
        <ThemeSwitch />
      </Box>
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Heading
          lineHeight={1.1}
          fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
        >
          Grow your agency{' '}
          <Text
            as={'span'}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            &
          </Text>{' '}
          Manage your streamers
        </Heading>
        {children}
      </Container>
      <Alert
        isOpen={isOpen}
        onClose={onCloseAlert}
        onClick={onClick}
        cancelRef={cancelRef}
        title={alertData.title}
        message={alertData.message}
      />
    </>
  );
};

export default AuthLayout;
