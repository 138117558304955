import { Flex, useControllableState } from '@chakra-ui/react';
import {
  ErrorFetchApi,
  Loading,
  SubHeaderComponent,
} from '../../../components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ColumnsTalentTable } from '../../../variables/UserTalents';
import DataTable, { createTheme } from 'react-data-table-component';
import { useMemo } from 'react';

const Talents = props => {
  const { agencyId, postData } = props;

  const queryClient = useQueryClient();

  const [filterText, setFilterText] = useControllableState({
    defaultValue: '',
  });

  const fetchParams = {
    field: 'accept',
    status: true,
    agency_id: agencyId,
  };

  const {
    isLoading,
    error,
    data,
    isError,
    isRefetching,
    isRefetchError,
    refetch,
  } = useQuery('talentUser', () => postData('engine/agency', fetchParams), {
    refetchOnWindowFocus: false,
  });

  const clearMutation = useMutation(
    async params => postData('engine/update_agency', params),
    {
      onMutate: async variables => {
        await queryClient.cancelQueries('talentUser');

        const previousRequest = queryClient.getQueryData('talentUser');

        return previousRequest;
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData('talentUser', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('talentUser');
      },
    }
  );

  const columns = ColumnsTalentTable(clearMutation);

  const subHeaderComponentMemo = useMemo(
    () => (
      <SubHeaderComponent
        refetch={refetch}
        filterText={filterText}
        setFilterText={setFilterText}
      />
    ),
    [filterText, refetch, setFilterText]
  );

  const filteredItems = Array.isArray(data)
    ? data.filter(
        item =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  if (isLoading || isRefetching) return <Loading type="bar" />;

  if (isError || isRefetchError || !data)
    return <ErrorFetchApi title={error !== null ? error.message : ''} />;

  createTheme('default', {
    text: {
      primary: '#53a9cf',
      secondary: '#53a9cf',
      disabled: '#53a9cf',
    },
    background: {
      default: 'transparent',
    },
    button: {
      default: '#53a9cf',
      disabled: '#53a9cf',
    },
  });

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <DataTable
        theme={'default'}
        pagination
        columns={columns}
        data={filteredItems}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        defaultSortFieldId={1}
      />
    </Flex>
  );
};

export default Talents;
