import { createBrowserRouter } from 'react-router-dom';
import Sign from './sign';
import Error from './error';
import Root from './root';
import Home from './home';
import Forgot from './forgot';

const routes = createBrowserRouter([
  { path: '/', element: <Root />, errorElement: <Error /> },
  { path: '/sign-in', element: <Sign /> },
  { path: '/forgot', element: <Forgot /> },
  { path: '/dashboard', element: <Home /> },
  { path: '/talent', element: <Home /> },
  { path: '/request', element: <Home /> },
]);

export { routes };
