import {
  Box,
  Center,
  CloseButton,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

const ErrorFetchApi = props => {
  const { title } = props;
  return (
    <Center flexDir={'column'} textAlign="center" py={10} px={6} h={'100vh'}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center"
        >
          <CloseButton boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {title}
      </Heading>
      <Text color={'gray.500'}>
        Failed to fetch the data, try to refresh your browser
      </Text>
    </Center>
  );
};

export default ErrorFetchApi;
