import Action from '../views/home/Request/components/RequestData';
import { useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { AvatarName, HeaderTitle } from '../views/home/components';

const ColumnsRequestTable = refetch => {
  const textColor = useColorModeValue('gray.700', 'white');

  return [
    {
      name: <HeaderTitle title={'name'} />,
      selector: row => row.name,
      sortable: true,
      cell: row => (
        <AvatarName type={'request'} row={row} textColor={textColor} />
      ),
      compact: true,
      minWidth: useBreakpointValue({ base: '40%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Action'} />,
      cell: row => <Action row={row} refetch={refetch} />,
      center: true,
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
  ];
};

export { ColumnsRequestTable };
