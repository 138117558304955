const lineChartOptions = {
  colors: ['#4FD1C5', '#839ecc', '#d9758b'],
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ['#4FD1C5', '#839ecc', '#d9758b'],
  },
  grid: {
    strokeDashArray: 5,
  },
  legend: {
    show: false,
  },
  noData: {
    text: 'Loading...',
  },
  tooltip: {
    theme: 'dark',
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'datetime',
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    labels: {
      style: {
        colors: '#c8cfca',
        fontSize: '12px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#c8cfca',
        fontSize: '12px',
      },
      formatter: function(val) {
        return val.toFixed(0);
      }
    },
  },
};

export { lineChartOptions };
