import { LinkBox, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import AuthForm from '../components/AuthForm';
import InputField from '../components/InputField';

const ForgotForm = props => {
  const { formData, onClick, handleInputChange } = props;
  const subTitle =
    'Enter your email and we will send you a link to reset your password via your email';
  return (
    <AuthForm
      title={'Reset password'}
      subTitle={subTitle}
      buttonTitle={'Request'}
      onClick={onClick}
      onSubmit={onClick}
    >
      <InputField
        placeholder={'Email'}
        name={'email'}
        handleInputChange={handleInputChange}
        value={formData.email}
        type={'email'}
      />
      <LinkBox
        textAlign={'end'}
        color={useColorModeValue('blue.400', 'blue.600')}
      >
        <Link to={'/sign-in'}>go back to login</Link>
      </LinkBox>
    </AuthForm>
  );
};

export default ForgotForm;
