import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate, useRouteError } from 'react-router-dom';
import { NotFound } from '../components';

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const isAuth = useIsAuthenticated();
  const goToHome = () =>
    isAuth()
      ? navigate('dashboard', { replace: true })
      : navigate('sign-in', { replace: true });

  return (
    error &&
    error.status === 404 && (
      <NotFound
        status={error.status}
        statusText={error.statusText}
        message={error.data}
        onClick={goToHome}
      />
    )
  );
};

export default Error;
