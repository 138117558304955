import { Text } from '@chakra-ui/react';

const HeaderTitle = props => {
  const { title } = props;

  return (
    <Text
      color="gray.400"
      fontSize={'sm'}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      textAlign={'center'}
    >
      {title}
    </Text>
  );
};

export default HeaderTitle;
