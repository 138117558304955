import { useBoolean } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate } from 'react-router-dom';
import { Splash } from '../components';

export const rootLoader = async props => {
  return props;
};

const Root = () => {
  const isAuth = useIsAuthenticated();
  const [splash, setSplash] = useBoolean(true);

  useEffect(() => {
    setTimeout(() => {
      setSplash.off();
    }, 1000);
    return () => {};
  }, [setSplash]);

  if (splash) {
    return <Splash />;
  }

  return isAuth() ? (
    <Navigate to={'dashboard'} replace />
  ) : (
    <Navigate to={'sign-in'} replace />
  );
};

export default Root;
