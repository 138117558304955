import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useLocation } from 'react-router-dom';
import Separator from '../Separator/Separator';
import CreateLink from './CreateLink';
import { menuData } from '../../variables/LinkMenu';

const SidebarResponsive = props => {
  const { logoText, logoImg } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const mainPanel = useRef();
  const location = useLocation();
  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : '';
  };

  return (
    <Flex
      display={{ sm: 'flex', xl: 'none' }}
      ref={mainPanel}
      alignItems="center"
    >
      <Button
        size={'md'}
        fontSize={'lg'}
        ms="0px"
        px="0px"
        me={{ sm: '2px', md: '16px' }}
        color={useColorModeValue('gray.600', 'gray.100')}
        variant="transparent-with-icon"
        leftIcon={<GiHamburgerMenu />}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius="16px"
          bg={useColorModeValue('teal.300', 'gray.700')}
        >
          <DrawerCloseButton
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="250px" px="1rem">
            <Box pt={'25px'} mb="12px">
              <Link
                href={''}
                target="_blank"
                display="flex"
                lineHeight="100%"
                mb="30px"
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                fontSize="11px"
              >
                <HStack>
                  <Center
                    w={'35px'}
                    h={'35px'}
                    borderRadius={'full'}
                    bg={'gray.200'}
                    mr={'5px'}
                  >
                    <Avatar src={logoImg} size={'sm'} />
                  </Center>
                  <Text fontSize="md">{logoText}</Text>
                </HStack>
              </Link>
              <Separator />
            </Box>
            <Stack direction="column" mb="40px">
              <Box>
                <CreateLink data={menuData} activeRoute={activeRoute} />
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default SidebarResponsive;
