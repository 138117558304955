import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaBell, FaPowerOff, FaSearch } from 'react-icons/fa';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';
import SidebarResponsive from '../Sidebar/SidebarResponsive';

const AdminNavbarLink = props => {
  const { handleSignout, logoText, logoImg } = props;

  return (
    <Flex
      pe={{ sm: '0px', md: '16px' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <InputGroup
        cursor="pointer"
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius="15px"
        w={{
          sm: '128px',
          md: '200px',
        }}
        me={{ sm: 'auto', md: '20px' }}
        _focus={{
          borderColor: useColorModeValue('teal.300', 'teal.300'),
        }}
        _active={{
          borderColor: useColorModeValue('teal.300', 'teal.300'),
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="none"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              icon={
                <FaSearch
                  color={useColorModeValue('gray.700', 'gray.200')}
                  w="15px"
                  h="15px"
                />
              }
            />
          }
        />
        <Input
          fontSize="xs"
          py="11px"
          color={useColorModeValue('gray.700', 'gray.200')}
          placeholder="Type here..."
          borderRadius="inherit"
        />
      </InputGroup> */}
      <Spacer />
      <Button
        size={'md'}
        fontSize={'lg'}
        ms="0px"
        px="0px"
        me={{ sm: '2px', md: '16px' }}
        color={useColorModeValue('gray.600', 'gray.100')}
        variant="transparent-with-icon"
        leftIcon={<FaPowerOff />}
        onClick={handleSignout}
      >
        <Text display={{ sm: 'none', md: 'flex' }}>Sign Out</Text>
      </Button>
      <SidebarResponsive logoText={logoText} logoImg={logoImg} />

      {/* <Menu>
        <MenuButton
          size={'md'}
          fontSize={'lg'}
          px="0px"
          mx={{ sm: '2px', md: '16px' }}
          color={useColorModeValue('gray.600', 'gray.100')}
          variant="transparent-with-icon"
        >
          <FaBell />
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px"></MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
      <ThemeSwitch />
    </Flex>
  );
};

export default AdminNavbarLink;
