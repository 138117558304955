import { Text, useDisclosure } from '@chakra-ui/react';
import { ActionButton, ActionModal } from '../../components';

const TextData = props => {
  const { row, textColor, type } = props;

  let text = '';

  switch (type) {
    case 'bg':
      text = row.totalBg + ' BG';
      break;

    case 'bonus':
      const bonus = (((5 / 100) * row.totalBg) / 7) * 165;
      text = bonus.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'code',
      });
      break;

    case 'duration':
      text = row.totalDuration;
      break;

    case 'last':
      text = row.streaming ? 'Live Now' : row.lastLive;
      break;

    case 'date':
      text = row.joinDate;
      break;

    default:
      break;
  }

  return (
    <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
      {text}
    </Text>
  );
};

const Action = props => {
  const { row, clearMutation } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteClick = () => {
    clearMutation.mutate({
      type: 'remove',
      objectId: row.objectId,
      agencyId: row.agencyId,
      userId: row.userId,
    });

    onClose();
  };

  return (
    <>
      <ActionButton onOpen={onOpen} text={'Delete'} bgColor={'red.400'} />

      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        headerTitle={'Delete Talent'}
        actionText={'Yes, remove it!'}
        colorScheme={'red'}
        onClick={handleDeleteClick}
      >
        Remove{' '}
        <Text as={'span'} fontWeight={'bold'}>
          {row.name}
        </Text>{' '}
        from your agency member.
      </ActionModal>
    </>
  );
};

export { TextData, Action };
