import { Action, TextData } from '../views/home/Talent/components/UsersData';
import { useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { AvatarName, HeaderTitle, Status } from '../views/home/components';

const columns = [
  {
    name: 'Date',
    selector: row => row.created,
    compact: true,
  },
  {
    name: 'Duration',
    selector: row => row.duration,
    center: true,
    compact: true,
  },
  {
    name: 'Viewers',
    selector: row => row.viewer,
    center: true,
    compact: true,
  },
  {
    name: 'Streaming BG',
    selector: row => row.bg,
    right: true,
    compact: true,
  },
];

const ColumnsTalentTable = clearMutation => {
  const textColor = useColorModeValue('gray.700', 'white');
  const modalSubHeaderColor = useColorModeValue('gray.900', 'gray.400');
  const modalDividerColor = useColorModeValue('gray.200', 'gray.600');
  const modalTitleColor = useColorModeValue('yellow.500', 'yellow.300');

  return [
    {
      name: <HeaderTitle title={'name'} />,
      selector: row => row.name,
      sortable: true,
      cell: row => (
        <AvatarName
          type={'talent'}
          row={row}
          textColor={textColor}
          columns={columns}
          modalSubHeaderColor={modalSubHeaderColor}
          modalDividerColor={modalDividerColor}
          modalTitleColor={modalTitleColor}
        />
      ),
      compact: true,
      minWidth: useBreakpointValue({ base: '40%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Total Streaming BG'} />,
      selector: row => row.totalBg,
      sortable: true,
      cell: row => <TextData type={'bg'} row={row} textColor={textColor} />,
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Bonus BG'} />,
      selector: row => row.totalBg,
      sortable: true,
      cell: row => <TextData type={'bonus'} row={row} textColor={textColor} />,
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Total Duration'} />,
      selector: row => row.totalDuration,
      sortable: true,
      cell: row => (
        <TextData type={'duration'} row={row} textColor={textColor} />
      ),
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Status'} />,
      selector: row => row.streaming,
      sortable: true,
      cell: row => <Status row={row} textColor={textColor} />,
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Last Streaming'} />,
      selector: row => row.lastLiveSort,
      sortable: true,
      cell: row => <TextData type={'last'} row={row} textColor={textColor} />,
      minWidth: useBreakpointValue({ base: '30%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Join Date'} />,
      selector: row => row.joinDate,
      sortable: true,
      cell: row => <TextData type={'date'} row={row} textColor={textColor} />,
      minWidth: useBreakpointValue({ base: '30%', lg: '0%' }, { ssr: false }),
    },
    {
      name: <HeaderTitle title={'Action'} />,
      cell: row => <Action row={row} clearMutation={clearMutation} />,
      minWidth: useBreakpointValue({ base: '20%', lg: '0%' }, { ssr: false }),
    },
  ];
};

export { ColumnsTalentTable };
