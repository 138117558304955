import {
  useBoolean,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthLayout from '../layouts/auth';
import ForgotForm from '../views/auth/forgotForm';

const Forgot = () => {
  const isAuth = useIsAuthenticated();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useBoolean();
  const [isSuccess, setIsSuccess] = useBoolean();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertData, setAlertData] = useControllableState({
    defaultValue: { title: '', message: '' },
  });
  const [formData, setFormData] = useControllableState({
    defaultValue: { email: '' },
  });

  const handleInputChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCloseAlert = () => {
    setAlertData({ title: '', message: '' });
    onClose();
  };

  const onSuccess = () => {
    onClose();
    navigate('/sign-in', { replace: true });
  };

  const handleOnClick = async () => {
    setIsLoading.on();
    if (formData.email.trim() === '') {
      setAlertData({
        ...alertData,
        title: 'Failed to reset',
        message: 'email cannot be empty',
      });

      setTimeout(() => {
        setIsLoading.off();
        onOpen();
      }, 1000);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
    ) {
      setAlertData({
        ...alertData,
        title: 'Failed to reset',
        message: 'please input your valid email',
      });

      setTimeout(() => {
        setIsLoading.off();
        onOpen();
      }, 1000);
    } else {
      axios
        .post(process.env.REACT_APP_ENGINE_URL + 'engine/reset', formData)
        .then(res => {
          if (res.data.status === 'error') {
            setAlertData({
              ...alertData,
              title: 'Failed to login',
              message: res.data.message,
            });
          } else {
            setAlertData({
              ...alertData,
              title: 'Request was successful',
              message: res.data.message,
            });

            setIsSuccess.on();
          }
        })
        .catch(err =>
          setAlertData({
            ...alertData,
            title: 'Error',
            message: err.message,
          })
        )
        .finally(() => {
          setIsLoading.off();
          onOpen();
        });
    }
  };

  return isAuth() ? (
    <Navigate to={'/dashboard'} replace />
  ) : (
    <AuthLayout
      isLoading={isLoading}
      isOpen={isOpen}
      onCloseAlert={onCloseAlert}
      onClick={isSuccess ? onSuccess : onCloseAlert}
      alertData={alertData}
    >
      <ForgotForm
        formData={formData}
        onClick={handleOnClick}
        handleInputChange={handleInputChange}
      />
    </AuthLayout>
  );
};

export default Forgot;
