import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const InputField = props => {
  const [show, setShow] = useBoolean();

  const { placeholder, name, handleInputChange, onKeyDown, value, type } =
    props;
  return (
    <InputGroup>
      <Input
        placeholder={placeholder}
        type={type === 'password' ? (show ? 'text' : 'password') : type}
        bg={'gray.100'}
        border={'1px'}
        borderColor={'gray.300'}
        color={'gray.500'}
        focusBorderColor={'pink.300'}
        _hover={{ borderColor: 'pink.300' }}
        _placeholder={{
          color: 'gray.500',
        }}
        name={name}
        onChange={handleInputChange}
        value={value}
        onKeyDown={onKeyDown && onKeyDown}
      />
      {type === 'password' && (
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={setShow.toggle}>
            {show ? <FaEyeSlash color="gray" /> : <FaEye color="gray" />}
          </Button>
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default InputField;
