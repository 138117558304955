import { Box, useColorModeValue } from '@chakra-ui/react';
import { useRef } from 'react';
import SidebarContent from './SidebarContent';

const Sidebar = props => {
  const { logoText, logoImg } = props;
  const mainPanel = useRef();

  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: 'none', xl: 'block' }} position="fixed">
        <Box
          bg={useColorModeValue('teal.300', 'gray.700')}
          transition={'0.2s linear'}
          w="260px"
          maxW="260px"
          ms={{ sm: '16px' }}
          my={{ sm: '16px' }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={'16px 0px 16px 16px'}
          borderRadius={'16px'}
        >
          <SidebarContent logoText={logoText} logoImg={logoImg} />
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
