import { Center } from '@chakra-ui/react';
import { Logo } from '../Logo/Logo';

const Splash = () => {
  return (
    <Center minH={'100vh'}>
      <Logo h="40vmin" pointerEvents="none" />
    </Center>
  );
};

export default Splash;
