import { Button, Text } from '@chakra-ui/react';

const ActionButton = props => {
  const { onOpen, text, bgColor } = props;
  return (
    <Button p="0px" bg={bgColor} px={'1em'} variant="no-hover" onClick={onOpen}>
      <Text fontSize="md" color="white" fontWeight="bold" cursor="pointer">
        {text}
      </Text>
    </Button>
  );
};

export default ActionButton;
