import { Flex, useColorModeValue } from '@chakra-ui/react';

const Separator = props => {
  return (
    <Flex
      h="1px"
      w="100%"
      bg={useColorModeValue(
        'linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #000000 49.52%, rgba(224, 225, 226, 0) 100%)',
        'linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0) 100%)'
      )}
    ></Flex>
  );
};

export default Separator;
