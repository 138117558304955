import { LinkBox, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthForm from '../components/AuthForm';
import InputField from '../components/InputField';

const SigninForm = props => {
  const { formData, onClick, handleInputChange, onKeyDown } = props;

  const subTitle =
    'Enter to your dashboard to see the process of achieving your streamers and manage them in the right way to achieve the target';

  return (
    <AuthForm
      title={'Sign in'}
      subTitle={subTitle}
      buttonTitle={'Login'}
      onClick={onClick}
      onSubmit={onClick}
    >
      <InputField
        placeholder={'Username'}
        name={'username'}
        handleInputChange={handleInputChange}
        value={formData.username}
        type={'text'}
      />
      <InputField
        placeholder={'Password'}
        name={'password'}
        handleInputChange={handleInputChange}
        value={formData.password}
        type={'password'}
        onKeyDown={onKeyDown}
      />
      <LinkBox
        textAlign={'end'}
        color={useColorModeValue('blue.400', 'blue.600')}
      >
        <Link to={'/forgot'}>forgot password ?</Link>
      </LinkBox>
    </AuthForm>
  );
};

export default SigninForm;
