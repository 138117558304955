import { Flex, useControllableState } from '@chakra-ui/react';
import {
  ErrorFetchApi,
  Loading,
  SubHeaderComponent,
} from '../../../components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataTable, { createTheme } from 'react-data-table-component';
import { useMemo } from 'react';
import { ColumnsRequestTable } from '../../../variables/RequestTalents';

const Request = props => {
  const { agencyId, postData } = props;

  const queryClient = useQueryClient();

  const [filterText, setFilterText] = useControllableState({
    defaultValue: '',
  });

  const fetchParams = {
    field: 'pending',
    status: true,
    agency_id: agencyId,
  };

  const {
    isLoading,
    error,
    data,
    isError,
    isRefetching,
    isRefetchError,
    refetch,
  } = useQuery(
    'requestUser',
    () => postData('engine/request_agency', fetchParams),
    {
      refetchOnWindowFocus: false,
    }
  );

  const clearMutation = useMutation(
    async params => postData('engine/update_agency', params),
    {
      onMutate: async variables => {
        await queryClient.cancelQueries('requestUser');

        const previousRequest = queryClient.getQueryData('requestUser');

        return previousRequest;
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData('requestUser', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('requestUser');
      },
    }
  );

  const columns = ColumnsRequestTable(clearMutation);

  const subHeaderComponentMemo = useMemo(
    () => (
      <SubHeaderComponent
        refetch={refetch}
        filterText={filterText}
        setFilterText={setFilterText}
      />
    ),
    [filterText, refetch, setFilterText]
  );

  const filteredItems = Array.isArray(data)
    ? data.filter(
        item =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  if (isLoading || isRefetching) return <Loading type="bar" />;

  if (isError || isRefetchError || !data)
    return <ErrorFetchApi title={error !== null ? error.message : ''} />;

  createTheme('default', {
    text: {
      primary: '#53a9cf',
      secondary: '#53a9cf',
      disabled: '#53a9cf',
    },
    background: {
      default: 'transparent',
    },
    button: {
      default: '#53a9cf',
      disabled: '#53a9cf',
    },
  });

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <DataTable
        theme={'default'}
        pagination
        columns={columns}
        data={filteredItems}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        dense
        defaultSortFieldId={1}
      />
    </Flex>
  );
};

export default Request;
