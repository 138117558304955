import ReactApexChart from 'react-apexcharts';
import { lineChartOptions } from '../../variables/ChartData';
import { useControllableState } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import axios from 'axios';

const LineChart = props => {
  const { agencyId, createdAt } = props;
  const [chartData, setChartData] = useControllableState({ defaultValue: [] });
  const [chartOptions, setChartOptions] = useControllableState({
    defaultValue: {},
  });

  const postData = useCallback(
    async () =>
      axios
        .post(process.env.REACT_APP_ENGINE_URL + 'engine/chart', {
          agency_id: agencyId,
          createdAt: createdAt,
        })
        .then(r => setChartData(r.data))
        .catch(err => err.message),
    [agencyId, createdAt, setChartData]
  );

  useEffect(() => {
    setChartOptions(lineChartOptions);
    postData();

    return () => {};
  }, [postData, setChartOptions]);

  return (
    <ReactApexChart
      type={'area'}
      width={'100%'}
      height={'100%'}
      options={chartOptions}
      series={chartData}
      // series={[
      //   { name: 'test', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40] },
      //   { name: 'test2', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10] },
      // ]}
    />
  );
};

export default LineChart;
