import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

const AuthForm = props => {
  const { title, subTitle, children, buttonTitle, onClick, onSubmit } = props;
  return (
    <Stack
      bg={useColorModeValue('gray.200', 'gray.100')}
      rounded={'xl'}
      p={{ base: 4, sm: 6, md: 8 }}
      spacing={{ base: 8 }}
      maxW={{ lg: 'lg' }}
    >
      <Stack spacing={4}>
        <Heading
          color={'gray.800'}
          lineHeight={1.1}
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
        >
          {title}
          <Text
            as={'span'}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            !
          </Text>
        </Heading>
        <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
          {subTitle}
        </Text>
      </Stack>
      <Box as={'form'} mt={10} onSubmit={onSubmit}>
        <Stack spacing={4}>{children}</Stack>
        <Button
          onClick={onClick}
          fontFamily={'heading'}
          mt={8}
          w={'full'}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={'white'}
          _hover={{
            bgGradient: 'linear(to-r, red.400,pink.400)',
          }}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Stack>
  );
};

export default AuthForm;
