import {
  Avatar,
  Badge,
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';

const AvatarName = props => {
  const {
    type,
    row,
    textColor,
    modalSubHeaderColor,
    modalDividerColor,
    modalTitleColor,
    columns,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        align="center"
        py=".8rem"
        minWidth="100%"
        flexWrap="nowrap"
        onClick={type === 'talent' ? onOpen : null}
        cursor={type === 'talent' && 'pointer'}
      >
        <Avatar src={row.avatar} w="50px" borderRadius="12px" me="18px" />

        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {row.name}
          </Text>
          <Badge
            maxWidth={'min-content'}
            bg={row.gender === 'male' ? 'blue.400' : 'red.400'}
            color={'white'}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
            mt={'5px'}
          >
            {row.gender}
          </Badge>
        </Flex>
      </Flex>

      {type === 'talent' && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={{ base: 'full', lg: '6xl' }}
          scrollBehavior={'inside'}
          isCentered
        >
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent>
            <ModalHeader />
            <ModalCloseButton />
            <ModalBody>
              <Container maxW={'7xl'}>
                <SimpleGrid
                  columns={{ base: 1, lg: 2 }}
                  spacing={{ base: 8, md: 10 }}
                  py={{ base: 18, md: 24 }}
                >
                  <Flex>
                    <Image
                      rounded={'md'}
                      alt={row.name}
                      src={row.avatar}
                      fit={'cover'}
                      align={'center'}
                      w={'100%'}
                      h={{ base: '100%', sm: '400px', lg: '500px' }}
                    />
                  </Flex>
                  <Stack spacing={{ base: 6, md: 10 }}>
                    <Box as={'header'}>
                      <HStack align={'start'}>
                        <Heading
                          lineHeight={1.1}
                          fontWeight={600}
                          fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                        >
                          {row.name}
                        </Heading>
                        {row.streaming && (
                          <Badge
                            ml="1"
                            bg={'green.400'}
                            color={'white'}
                            fontSize="xl"
                            px={5}
                          >
                            {'live now'}
                          </Badge>
                        )}
                      </HStack>
                      <Text
                        color={modalSubHeaderColor}
                        fontWeight={300}
                        fontSize={'2xl'}
                      >
                        Joineed on the {row.joinDate}
                      </Text>
                    </Box>

                    <Stack
                      spacing={{ base: 4, sm: 6 }}
                      direction={'column'}
                      divider={<StackDivider borderColor={modalDividerColor} />}
                    >
                      <Box>
                        <Text
                          fontSize={{ base: '16px', lg: '18px' }}
                          color={modalTitleColor}
                          fontWeight={'500'}
                          textTransform={'uppercase'}
                          mb={'4'}
                        >
                          summary
                        </Text>

                        <List spacing={2}>
                          <ListItem>
                            <Text as={'span'} fontWeight={'bold'}>
                              Join date:
                            </Text>{' '}
                            {row.joinDate}
                          </ListItem>
                          <ListItem>
                            <Text as={'span'} fontWeight={'bold'}>
                              BG (this month):
                            </Text>{' '}
                            {row.totalBg} BG
                          </ListItem>
                          <ListItem>
                            <Text as={'span'} fontWeight={'bold'}>
                              Live duration (this month):
                            </Text>{' '}
                            {row.totalDuration}
                          </ListItem>
                          <ListItem>
                            <Text as={'span'} fontWeight={'bold'}>
                              Last live:
                            </Text>{' '}
                            {row.streaming ? 'Live Now' : row.lastLive}
                          </ListItem>
                        </List>
                      </Box>

                      <Box>
                        <Text
                          fontSize={{ base: '16px', lg: '18px' }}
                          color={modalTitleColor}
                          fontWeight={'500'}
                          textTransform={'uppercase'}
                          mb={'4'}
                        >
                          details streaming
                        </Text>
                        <DataTable
                          dense
                          columns={columns}
                          data={row.userDetails}
                          defaultSortAsc={false}
                          defaultSortFieldId={1}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </SimpleGrid>
              </Container>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AvatarName;
