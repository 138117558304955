import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { IoCloseCircle, IoRefresh } from 'react-icons/io5';

const SubHeaderComponent = props => {
  const { refetch, setFilterText, filterText } = props;
  return (
    <HStack>
      <IconButton
        colorScheme="blue"
        icon={<IoRefresh />}
        onClick={() => refetch()}
      />

      <InputGroup>
        <Input
          htmlSize={20}
          width={'auto'}
          isInvalid
          errorBorderColor={'teal.200'}
          onChange={e => setFilterText(e.target.value)}
          value={filterText}
        />
        {filterText !== '' && (
          <InputRightElement onClick={() => setFilterText('')}>
            <IoCloseCircle color="red" />
          </InputRightElement>
        )}
      </InputGroup>
    </HStack>
  );
};

export default SubHeaderComponent;
