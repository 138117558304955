import { HStack, Text, useDisclosure } from '@chakra-ui/react';
import { ActionButton, ActionModal } from '../../components';

const Action = props => {
  const { row, refetch } = props;
  const acceptUser = useDisclosure();
  const rejectUser = useDisclosure();

  const handleAcceptTalent = () => {
    refetch.mutate({
      type: 'accept',
      objectId: row.objectId,
      agencyId: row.agencyId,
      userId: row.userId,
    });

    acceptUser.onClose();
  };

  const handleDeclineTalent = () => {
    refetch.mutate({
      type: 'decline',
      objectId: row.objectId,
      agencyId: row.agencyId,
    });

    rejectUser.onClose();
  };

  return (
    <>
      <HStack>
        <ActionButton
          onOpen={acceptUser.onOpen}
          text={'Accept'}
          bgColor={'blue.400'}
        />
        <ActionButton
          onOpen={rejectUser.onOpen}
          text={'Decline'}
          bgColor={'red.400'}
        />
      </HStack>

      <ActionModal
        isOpen={acceptUser.isOpen}
        onClose={acceptUser.onClose}
        headerTitle={'Accept Talent'}
        actionText={'Yes, do it!'}
        colorScheme={'blue'}
        onClick={handleAcceptTalent}
      >
        Accept{' '}
        <Text as={'span'} fontWeight={'bold'}>
          {row.name}
        </Text>{' '}
        as your agency member.
      </ActionModal>

      <ActionModal
        isOpen={rejectUser.isOpen}
        onClose={rejectUser.onClose}
        headerTitle={'Reject Talent'}
        actionText={'Yes, decline it!'}
        colorScheme={'red'}
        onClick={handleDeclineTalent}
      >
        Decline{' '}
        <Text as={'span'} fontWeight={'bold'}>
          {row.name}
        </Text>{' '}
        to be your agency member.
      </ActionModal>
    </>
  );
};

export default Action;
